import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

export default class CronofyService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  public async getInvitationUrl (smsThreadId: string, eventDuration: string) {
    const returnedUrl = await this.$axios.$post(`/api/integrations/calendar/${smsThreadId}/link`, { event_duration: eventDuration })
    return returnedUrl.link
  }

  public getCredentials () {
    return this.$axios.$get('/api/integrations/calendar/element-token')
  }

  public getCalendar (eventId: string) {
    return this.$axios.$get(`/api/integrations/calendar/${eventId}/picker`)
  }

  public getCalendars () {
    return this.$axios.$get('/api/integrations/calendar/configuration')
  }

  public setDefaultCalendar (defaultCalendar: string) {
    return this.$axios.$post('/api/integrations/calendar/configuration', { default_calendar: defaultCalendar })
  }

  public scheduleMeeting (eventId: string, { start, end }:{start: string, end: string}) {
    return this.$axios.$post(`/api/integrations/calendar/${eventId}/picker`, { start, end })
  }

  public addCalendar (code: string) {
    return this.$axios.$post('/api/integrations/calendar/auth', { code })
  }
}
